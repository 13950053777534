import React, { useState } from "react";
import ThumbsUpIcon from "./symbols/ThumbsUp";
import ThumbsDownIcon from "./symbols/ThumbsDown";
import InvestigateIcon from "./symbols/InvestigateIcon";
import { useAxiosWrapper } from "./axiosWrapper";
import { useNavigate } from "react-router-dom";
import "./MessageChunk.css";
import "./Icons.scss";
import { components } from "./api-types.gen";
import UndoIcon from "./symbols/UndoIcon";
import RedoIcon from "./symbols/RedoIcon";

type EventRatingState = components["schemas"]["EventRatingState"];

interface MessageChunkProps {
  userInput: boolean;
  paragraphs: { plain_text: string }[];
  gameUUID: string;
  eventUUID: string;
  lastEventUUID: string;
  peekAccess: boolean;
  writeAccess: boolean;
  isFinalUserInput: boolean;
  redoEventUUIDs: string[] | null;
  refetchGameInterface: () => void;
  setIsLoading: (value: boolean) => void;
  lastRating?: EventRatingState | null;
}

const MessageChunk: React.FC<MessageChunkProps> = ({
  userInput,
  paragraphs,
  gameUUID,
  eventUUID,
  lastEventUUID,
  peekAccess,
  writeAccess,
  redoEventUUIDs,
  isFinalUserInput,
  refetchGameInterface,
  setIsLoading,
  lastRating = null,
}) => {
  const navigate = useNavigate();
  const [visibleRating, setVisibleRating] = useState(
    lastRating ? lastRating.rating : "not_rated"
  );
  const axiosWrapper = useAxiosWrapper();

  async function setRating(rating: "thumbs_up" | "thumbs_down" | "not_rated") {
    console.log("Set new rating for", eventUUID, rating);
    setVisibleRating(rating);
    await axiosWrapper({
      method: "POST",
      url: `/api/games/${gameUUID}/events/${eventUUID}/rate`,
      headers: { "Content-Type": "application/json" },
      data: {
        rating: rating,
      },
    });
  }

  async function handleRedoClick() {
    if (!redoEventUUIDs || !redoEventUUIDs.length) return;

    const nextUUID = redoEventUUIDs[redoEventUUIDs.length - 1];

    setIsLoading(true);
    await axiosWrapper({
      method: "POST",
      url: `/api/games/${gameUUID}/redo`,
      headers: { "Content-Type": "application/json" },
      data: {
        chosen_next_event_uuid: nextUUID,
        last_event_uuid: eventUUID,
      },
    });
    setIsLoading(false);
    refetchGameInterface();
  }

  async function handleUndoClick() {
    setIsLoading(true);
    await axiosWrapper({
      method: "POST",
      url: `/api/games/${gameUUID}/undo`,
      headers: { "Content-Type": "application/json" },
      data: {
        event_to_undo_uuid: eventUUID,
        last_event_uuid: lastEventUUID,
      },
    });
    setIsLoading(false);
    refetchGameInterface();
  }

  async function handleThumbsUpClick() {
    if (visibleRating === "thumbs_up") {
      setRating("not_rated");
    } else {
      setRating("thumbs_up");
    }
  }

  async function handleThumbsDownClick() {
    if (visibleRating === "thumbs_down") {
      setRating("not_rated");
    } else {
      setRating("thumbs_down");
    }
  }

  async function handleInvestigateClick() {
    navigate(`/explain/games/${gameUUID}/events/${eventUUID}`);
  }

  return (
    <div className={`message-chunk ${userInput ? "user" : ""}`}>
      <div className="indicator-container">
        <span className="message-icon">{userInput ? "❯" : "❮"}</span>
      </div>
      <div className="message-content">
        {paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph.plain_text}</p>
        ))}
      </div>

      <div className="action-icons">
        {userInput && writeAccess && isFinalUserInput && (
          <UndoIcon className="icon-default" onClick={handleUndoClick} />
        )}
        {!userInput && (
          <>
            {peekAccess && (
              <InvestigateIcon
                className="icon-default"
                onClick={handleInvestigateClick}
              />
            )}

            <div onClick={handleThumbsUpClick}>
              <ThumbsUpIcon
                className={
                  visibleRating === "thumbs_up" ? "icon-green" : "icon-default"
                }
              />
            </div>
            <div onClick={handleThumbsDownClick}>
              <ThumbsDownIcon
                className={
                  visibleRating === "thumbs_down" ? "icon-red" : "icon-default"
                }
              />
            </div>
          </>
        )}
        {writeAccess &&
          redoEventUUIDs &&
          redoEventUUIDs?.length > 0 &&
          eventUUID == lastEventUUID && (
            <RedoIcon className="icon-default" onClick={handleRedoClick} />
          )}
      </div>
    </div>
  );
};

export default MessageChunk;
