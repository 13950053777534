import React, { useState, useRef, useEffect } from "react";
import MessageChunk from "./MessageChunk";
import Spinner from "./Spinner";
import { useAxiosWrapper } from "./axiosWrapper";

import "./Chat.css";

import { components } from "./api-types.gen";

type UndoRequest = components["schemas"]["UndoRequest"];
type UndoResponse = components["schemas"]["UndoResponse"];
type ChatInterfaceState = components["schemas"]["ChatInterfaceState"];
type SendMessageResponse = components["schemas"]["SendMessageResponse"];

type ChatProps = {
  gameId: string;
  chatState: ChatInterfaceState;
  lastMessageId: string | null;
  writeAccess: boolean;
  peekAccess: boolean;
  refetchGameInterface: () => void;
};

const Chat: React.FC<ChatProps> = ({
  gameId,
  chatState,
  writeAccess,
  peekAccess,
  refetchGameInterface,
}) => {
  const axiosWrapper = useAxiosWrapper();

  const [chatHistory, setChatHistory] = useState<any[]>(chatState.chat_history);
  const [lastLoadedHead, setLastLoadedHead] = useState<string | null>(null);
  const [redoNextUUIDs, setRedoNextUUIDs] = useState<string[] | null>(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const chatInputRef = useRef<HTMLInputElement>(null);
  const chatLogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setChatHistory(chatState.chat_history);
    setLastLoadedHead(
      chatState.chat_history[chatState.chat_history.length - 1].event_uuid ||
        null
    );
    setRedoNextUUIDs(chatState.redoable_next_event_uuids || null);
    chatInputRef.current?.focus();
    setIsLoading(false);
  }, [chatState]);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const trimmedMessage = message.trim();
    if (!trimmedMessage) return;

    setMessage("");

    const userChunk = {
      user_input: true,
      paragraphs: [
        {
          plain_text: trimmedMessage,
        },
      ],
    };
    setChatHistory((prevHistory) => [...prevHistory, userChunk]);
    setIsLoading(true);

    try {
      const response = await sendRequest(trimmedMessage, gameId);
      setChatHistory((prevHistory) => [
        ...prevHistory,
        response.response_chunk,
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function sendRequest(message: string, gameId: string) {
    const data: SendMessageResponse = await axiosWrapper({
      method: "POST",
      url: "/api/send-message",
      headers: { "Content-Type": "application/json" },
      data: {
        message,
        game_id: gameId,
      },
    });
    return data;
  }

  function getLastEventUUID() {
    const chunks: any[] = chatHistory;
    const n = chunks.length;
    if (n < 1) return null;

    const lastChunk = chunks[n - 1];
    console.log("Last chunk:", lastChunk);
    if (lastChunk.event_uuid) {
      return lastChunk.event_uuid as string;
    }
    return null;
  }

  function beginReloadingPage() {
    setIsLoading(true);
    refetchGameInterface();
  }

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }
  }, [chatHistory]);

  function getFinalUserInputIndex() {
    const chunks: any[] = chatHistory;
    const n = chunks.length;
    if (n < 1) return null;

    for (let i = n - 1; i >= 0; i--) {
      if (chunks[i].user_input) {
        return i;
      }
    }
    return null;
  }

  return (
    <div className="chat-container">
      <div className="chat-log" ref={chatLogRef}>
        {chatHistory.map((chunk, index) => (
          <MessageChunk
            key={index}
            userInput={chunk.user_input}
            gameUUID={gameId}
            eventUUID={chunk.event_uuid}
            lastEventUUID={getLastEventUUID() || ""}
            paragraphs={chunk.paragraphs}
            lastRating={chunk.last_rating}
            peekAccess={peekAccess}
            writeAccess={writeAccess}
            isFinalUserInput={index === getFinalUserInputIndex()}
            refetchGameInterface={beginReloadingPage}
            setIsLoading={setIsLoading}
            redoEventUUIDs={
              index === chatHistory.length - 1 &&
              chunk.event_uuid === lastLoadedHead &&
              redoNextUUIDs &&
              redoNextUUIDs.length > 0
                ? redoNextUUIDs
                : null
            }
          />
        ))}
        {isLoading && <Spinner />}
      </div>

      {writeAccess && (
        <form className="chat-form" onSubmit={handleSubmit}>
          <input
            type="text"
            className="chat-input"
            ref={chatInputRef}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            disabled={isLoading}
          />
          <button type="submit" className="send-button" disabled={isLoading}>
            Send
          </button>
        </form>
      )}
    </div>
  );
};

export default Chat;
